@import url("https://fonts.googleapis.com/css?family=Amiri|Lato:300,900&amp;display=swap");
:root {
  --black: #0e0d0b;
  --white: #f1f2f4;
}
*,
:after,
:before {
  box-sizing: border-box;
}
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
button {
  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
  font-size: inherit;
}
input,
select {
  font-size: inherit;
  font-family: inherit;
}
pre {
  font-family: monospace;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
textarea {
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
hr {
  height: 1px;
  background: var(--border);
  margin: 0;
  padding: 0;
  border: none;
}
.text-sm {
  font-size: 0.875rem;
}
.text-big {
  font-size: 2.25rem;
  font-family: Amiri, serif;
}
body,
html {
  width: 100%;
  height: auto;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
body {
  font-family: Lato, sans-serif;
  font-size: 16px;
  color: var(--white);
  line-height: 1.5;
  background-color: var(--black);
  position: absolute;
  overflow-x: hidden;
}
.container {
  padding-left: 40px;
  padding-right: 40px;
}
.top__link {
  position: absolute;
  z-index: 10;
  padding: 20px;
  top: 20px;
  right: 20px;
}
.top__link.link__home {
  top: 20px;
  right: 100px;
}
.footer {
  position: absolute;
  z-index: 10;
  bottom: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  mix-blend-mode: difference;
}
.footer .github {
  display: none;
}
.screen__transition {
  position: fixed;
  z-index: 1000;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.screen__transition .screen__black,
.screen__transition .screen__white {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.screen__transition .screen__black {
  background-color: var(--black);
}
.screen__transition .screen__white {
  background-color: var(--white);
}
.projects {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.projects .projects__list {
  height: 50%;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.projects .projects__link {
  line-height: 110%;
  overflow: hidden;
}
.projects .projects__link a {
  display: inline-block;
  color: #fff;
  mix-blend-mode: difference;
  transition: all 0.2s ease-in;
}
.projects .projects__link .project__img {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
  clip-path: polygon(0 50%, 100% 50%, 100% 50%, 0 50%);
  transition: -webkit-clip-path 0.5s ease-in-out;
  transition: clip-path 0.5s ease-in-out;
  transition: clip-path 0.5s ease-in-out, -webkit-clip-path 0.5s ease-in-out;
  opacity: 0;
}
.projects .projects__link .project__img img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}
.projects .projects__link .project__img.clicked {
  opacity: 1;
}
.project__title {
  height: 50vh;
  height: calc(var(--vh, 1vh) * 50);
  display: flex;
  align-items: center;
}
.project__title h1 {
  overflow: hidden;
}
.project__title h1 span {
  display: inline-block;
}
.project__link a {
  text-transform: uppercase;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 0.875rem;
}
.project__infos {
  margin: 40px auto;
}
.project__infos div {
  margin-bottom: 40px;
  padding-right: 50px;
}
.project__infos div span {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  opacity: 0.75;
}
.project__infos div p {
  font-size: 1.125rem;
}
.project__images img {
  display: block;
  margin-top: 25px;
  transform: scale(0.9);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.project__images img.img-big {
  transform: scale(0.96);
}
.project__images img.active {
  transform: translateY(0) scale(1);
}
.project__footer {
  display: flex;
  justify-content: space-between;
  background-color: var(--white);
  color: var(--black);
  height: 190px;
  padding-top: 30px;
}
.project__footer a:last-child {
  text-align: right;
  max-width: 40%;
}
.project__footer span {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.625rem;
  position: relative;
  transition: transform 0.2s ease-in-out;
}
.project__footer p {
  font-size: 1.5rem;
  font-family: Amiri, serif;
  line-height: 1;
}
.about {
  position: relative;
  z-index: 20;
  width: 100%;
  background-color: var(--black);
}
.about .top__link {
  z-index: 40;
}
.about__infos {
  font-size: 1.125rem;
  width: 100%;
}
.about__infos .about__photo {
  width: 100%;
  height: 60vh;
  height: calc(var(--vh, 1vh) * 60);
  overflow: hidden;
  background-image: linear-gradient(0deg, var(--black), transparent 25%);
  background-size: cover;
  background-position: 50% 50%;
}
.about__infos .about__perso {
  position: relative;
  top: -10vh;
  top: calc(var(--vh, 1vh) * -10);
  margin-bottom: -10vh;
  margin-bottom: calc(var(--vh, 1vh) * -10);
}
.about__infos .about__perso > p {
  margin-bottom: 40px;
}
.about__infos .about__perso > div {
  margin-bottom: 20px;
}
.about__infos .about__perso > div span {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 700;
  opacity: 0.75;
}
.about__infos .about__perso .about__scroll {
  margin-bottom: 0;
  height: 0;
}
.about__description {
  position: relative;
  z-index: 30;
  margin-top: 70px;
  margin-bottom: 40px;
  background-color: var(--black);
}
.about__description p {
  margin-bottom: 20px;
  font-size: 1.5rem;
}
@-webkit-keyframes scroll {
  0% {
    transform: translateY(100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes scroll {
  0% {
    transform: translateY(100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 0;
  }
}
#particles-js {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
@media (min-width: 768px) {
  .text-sm {
    font-size: 1rem;
  }
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
  .top__link {
    top: 30px;
    right: 30px;
    position: fixed;
    mix-blend-mode: difference;
  }
  .top__link.link__home {
    top: 70px;
    right: 30px;
  }
  .footer .github {
    display: flex;
  }
  .project__infos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .project__year {
    width: 25%;
  }
  .project__role {
    width: 50%;
  }
  .project__description {
    width: 75%;
  }
  .project__images {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
  }
  .project__images img {
    margin-top: 50px;
  }
  .project__footer span {
    font-size: 0.825rem;
  }
  .project__footer p {
    font-size: 2rem;
  }
  .about__infos {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: flex-end;
    background-color: var(--black);
  }
  .about__infos .about__photo {
    width: 50%;
    height: 100%;
    background-image: linear-gradient(-90deg, var(--black), transparent 25%);
  }
  .about__infos .about__perso {
    top: auto;
    margin-bottom: 0;
  }
  .about__infos .about__perso .about__scroll {
    margin-bottom: 0;
    margin-top: 100px;
    height: 100px;
    width: 1px;
    background-color: var(--white);
    position: relative;
    -webkit-animation: scroll 1s ease-out infinite;
    animation: scroll 1s ease-out infinite;
  }
  .about__description {
    min-height: 100vh;
    margin-top: 100vh;
    margin-top: calc(var(--vh, 1vh) * 100);
    margin-bottom: 0;
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .about__description:last-child {
    margin-bottom: 0;
  }
  .about__description {
    padding-right: 200px !important;
  }
  .about__description p {
    margin-bottom: 50px;
    font-size: 2.25rem;
  }
}
@media (min-width: 992px) {
  .text-big {
    font-size: 3.5rem;
  }
  .container {
    padding-left: 162px;
    padding-right: 162px;
  }
  .link {
    position: relative;
  }
  .link:after {
    content: "";
    position: absolute;
    width: 0;
    height: 1px;
    display: block;
    right: 0;
    background: #fff;
    transition: width 0.2s ease;
  }
  .link:hover:after {
    width: 100%;
    left: 0;
    background: #fff;
  }
  .footer {
    position: fixed;
    bottom: 50%;
    left: 50px;
    transform: translate(-50%, -50%) rotate(-90deg);
    width: 100vh;
    width: calc(var(--vh, 1vh) * 100);
  }
  .projects .projects__list {
    height: 75%;
    max-height: 500px;
    width: 100%;
  }
  .projects .projects__link {
    line-height: 125px;
    overflow: hidden;
    position: relative;
  }
  .projects .projects__link .project__img {
    transition: all 0.3s ease-in-out;
    opacity: 1;
  }
  .projects .projects__link .projects__item .project__img.clicked,
  .projects .projects__link .projects__item:hover .project__img {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  .project__text {
    position: fixed;
    top: 0;
    height: 95vh;
    height: calc(var(--vh, 1vh) * 95);
  }
  .project__text .project__infos.asleep,
  .project__text .project__link.asleep,
  .project__text .project__title.asleep {
    opacity: 0;
  }
  .project__images {
    margin-top: 95vh;
    padding-left: 162px;
    padding-right: 162px;
    position: relative;
    z-index: 2;
    padding-bottom: 162px;
  }
  .project__images img:first-child {
    transform: translateY(0);
  }
  .project__images img {
    margin-top: 100px;
  }
  .project__footer {
    align-items: center;
    height: 230px;
    padding-top: 0;
    position: relative;
    z-index: 2;
  }
  .project__footer .footer__next span,
  .project__footer .footer__prev span {
    transition: transform 0.2s ease-in-out;
  }
  .project__footer .footer__next span:after,
  .project__footer .footer__prev span:after {
    font-size: 1.125rem;
    position: absolute;
    bottom: -3px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .project__footer .footer__next:hover span:after,
  .project__footer .footer__prev:hover span:after {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  .project__footer .footer__prev span:after {
    content: "<";
    left: -20px;
  }
  .project__footer .footer__prev:hover span {
    transform: translateX(20px);
  }
  .project__footer .footer__next span:after {
    content: ">";
    right: -20px;
  }
  .project__footer .footer__next:hover span {
    transform: translateX(-20px);
  }
  .project__footer p {
    font-size: 2.5rem;
    line-height: 150%;
  }
}
@media (min-width: 1200px) {
  .project__year {
    width: 12.5%;
  }
  .project__role {
    width: 25%;
  }
  .project__description {
    width: 50%;
  }
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cursor {
  width: 40px;
  height: 40px;
  border: 2px solid #fefefe;
  border-radius: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 150ms ease;
  transition-property: background-color, opacity, transform, mix-blend-mode;
  z-index: 9999;
  mix-blend-mode: difference;
}

.cursor--hidden {
  opacity: 0;
}

.cursor--link-hovered {
  transform: translate(-50%, -50%) scale(0.8);
  background-color: #fefefe;
}

.cursor--link-hovered-title {
  transform: translate(-50%, -50%) scale(1.5);
  background-color: #fefefe;
}

.cursor--clicked {
  transform: translate(-50%, -50%) scale(1);
  background-color: #fefefe;
}

html *,
body * {
  cursor: none;
}

p,
a,
h1 {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
