@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");

$red: #e20001;
$black: #0e0d0b;
$white: #fffdfd;

html,
body {
  background-color: $black;
  color: $white;
  cursor: "none";
}
